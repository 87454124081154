//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MyPagination from '../../my-pagination.vue'
export default {
    props: [],
    data () {
        return {
            pgData: {
                title: '',
                txtkey: ''
            }
        }
    },
    methods: {
        searchHandler() {
            alert('建议调用my-pagination组件的queryData方法');
        }
    },
    mounted: function() {
        
    }
}
